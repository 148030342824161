<script lang="ts">
import { defineComponent } from "vue";
import '../assets/vue.scss'
import '../assets/intiferreira.scss'
import '../assets/brick.scss'
import { isEmpty } from "lodash";
import {VariantTool} from "@/util/VariantTool";
import {API} from "@/api/API";

export default defineComponent({
    props: {
        line_item_id: {
            type: Number
        },
        sku: {
            type: String
        },
        name: {
            type: String
        },
        description: {
            type: String
        },
        order_number: {
            type: String
        },
        sale_price: {
            type: Number
        }
    },
    data() {
        const form: {
                line_item_id: number,
                sku: string,
                name: string,
                description: string,
                order_number: string,
                sale_price: number,
              } = {
                        line_item_id: VariantTool.IdZero(this.line_item_id) as number,
                        sku: VariantTool.IdZero(this.sku) as string,
                        name: VariantTool.IdZero(this.name) as string,
                        description: VariantTool.IdZero(this.description) as string,
                        order_number: VariantTool.IdZero(this.order_number) as string,
                        sale_price: VariantTool.IdZero(this.sale_price) as number,
                      };

        return {
            form
        };
    },
    async mounted() {
        console.log("line item set price mounted!");
        this.init();
    },
    methods: {
        async init() {
            if (!this.$store.state.cart) {
              console.log("Cart was null");
              console.log("local storage orderToken", localStorage.getItem("orderToken"));
              console.log("", JSON.stringify(this.$store.state.accessToken));
              if (isEmpty(this.$store.state.accessToken.orderToken)) {
                await this.$store.dispatch("doCreateCart");
              } else {
                await this.readCart();
              }
            }
        },
        async readCart() {
            console.log("Reading the cart");
                const response = await this.$store.state.client.cart.show(
                  {orderToken: this.$store.state.accessToken.orderToken},
                  {include: "line_items"}
                );

                if (response.isSuccess()) {
                  await this.$store.dispatch("doUpdateCart", response.success());
                } else {
                  await this.$store.dispatch("doCreateCart");
                }
        },
        async onSubmit() {
            const response = await API.updateRequest(
                     this, API.updateLineItemSalePrice(this.form.order_number, this.form.line_item_id, this.form.sale_price),
                     () => "Error while updating salePrice",
                     () => `Sale price set for item ${this.form.sku}`);
            if (response) {
              await this.readCart();
              this.$bvModal.hide('line-item-sale-price-edit-modal');
            }
        }
    },
})
</script>
<template>
  <div id="LineItemSalePriceEdit">
    <h3> SKU {{ form.sku }}</h3>
    <p><b>Name: </b> {{ form.name }} </p>
    <!--           <p><b>Price: </b> {{ this.form. }} </p>-->

    <b-form @submit="onSubmit">
      <b-form-group
        id="set-sale-price-group"
        label="LineItem Sale Price"
        label-for="set-sale-price-input"
        description="Set Sale Price"
      >
        <b-form-input
          id="set-sale-price-input"
          v-model="form.sale_price"
          type="number"
          step="0.01"
          min="0"
          placeholder="Enter sale price"
        />
        <b-button
          type="submit"
          variant="primary"
        >
          Set sale price
        </b-button>
      </b-form-group>
    </b-form>
  </div>
</template>
