<script lang="ts">
import  { defineComponent } from "vue";
import '../assets/vue.scss'
import '../assets/intiferreira.scss'
import '../assets/brick.scss'

export default defineComponent({
    name: "ControlInventory",
    data() {
        const show: boolean = true;
        const form: { email: string, password: string } = { email: "", password: ""};
        const user = {};

        return {
            form,
            show,
            user,
        };
    },
    async mounted() {
        if (!this.$store.state.user && localStorage.user) {
          this.user = localStorage.user;
        }
        console.log("control inventory mounted!")
        await this.init();
    },
    methods: {
        async init() {

        },
        async reset() {
            await this.$store.dispatch("doResetCart");
        }
    }
})
</script>
<template>
  <div id="ControlInventoryPage">
    <salespoint-header />
    <h1> Control Inventory </h1>
    <b-container class="bv-example-row">
      <b-row>
        <b-col>
          <control-inventory-add-form />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>




