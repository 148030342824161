<script lang="ts">
import '../assets/vue.scss'
import '../assets/intiferreira.scss'
import '../assets/brick.scss'
import {API} from "@/api/API";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SendInvoiceMail",
    data() {
        const form: { email: string } = { email: ""};

        return {
            form
        };
    },
    computed: {
      cartInfo() {
          if (this.$store.state.cart && this.$store.state.cart.data) {
            console.log("cartInfo: ", JSON.stringify(this.$store.state.cart.data), "\n ---- cartInfo ----");
            return this.$store.state.cart.data.attributes;
          } else {
            return {};
          }
        }
    },
    methods: {
        async sendInvoice(number: string) {
            try {
                  console.log("Sending invoice number: ", number, "to: ", this.form.email);
                  API.getRequest(this, API.sendInvoicePDF(number, this.form.email), () => 'Found', 'blob').then(
                    response => {
                      const blob = new Blob([response.data], { type: 'application/pdf' });
                      const url = window.URL.createObjectURL(blob);

                      const link = document.createElement('a');
                      link.href = url;
                      link.download=`invoice_${number}.pdf`;
                      link.click();
                    }
                  );


                } catch (e) {
                  this.$bvToast.toast(`Could not generate and send the invoice for the order ${number}`);
                }
        }
    },
})

</script>
<template>
  <div id="SendInvoiceMail">
    <b-form
      class="w-100"
      inline
      @submit="sendInvoice(cartInfo.number)"
    >
      <b-list-group>
        <b-list-group-item>
          <label
            class="sr-only"
            for="inline-form-input-name"
          >Client email address</label>
          <b-input
            id="client-email-address"
            v-model="form.email"
            class="w-100"
            type="email"
            placeholder="Enter Client Email"
          />
        </b-list-group-item>
        <b-list-group-item>
          <b-button
            class="w-100"
            type="submit"
            variant="primary"
          >
            Send Invoice
          </b-button>
        </b-list-group-item>
      </b-list-group>
    </b-form>
  </div>
</template>
