<script lang="ts">
import { defineComponent } from "vue";
import '../assets/vue.scss'
import '../assets/intiferreira.scss'
import '../assets/brick.scss'
import { isEmpty } from "lodash";
import {VariantTool} from "@/util/VariantTool";
import {ProductAPI} from "@/api/ProductAPI";

export default defineComponent({
    name: "ProductSalePriceEdit",
    props: {
        product_id: {
            type: Number
        },
        sku: {
            type: String
        },
        name: {
            type: String
        },
        description: {
            type: String
        },
        price: {
            type: Number
        },
        sale_price: {
            type: Number
        }
    },
    data() {
        const form: {
                product_id: number,
                sku: string,
                name: string,
                description: string,
                price: number,
                sale_price: number,
              } = {
                        product_id: VariantTool.IdZero(this.product_id) as number,
                        sku: VariantTool.IdZero(this.sku) as string,
                        name: VariantTool.IdZero(this.name) as string,
                        description: VariantTool.IdZero(this.description) as string,
                        price: VariantTool.IdZero(this.price) as number,
                        sale_price: VariantTool.IdZero(this.sale_price) as number,
                      };

        return {
            form
        };
    },
    async mounted() {
        console.log("line item mounted!");
        this.init();
    },
    methods: {
        async init() {
            if (!this.$store.state.cart) {
                  console.log("Cart was null");
                  console.log("local storage orderToken", localStorage.getItem("orderToken"));
                  console.log("", JSON.stringify(this.$store.state.accessToken));
                  if (isEmpty(this.$store.state.accessToken.orderToken)) {
                    await this.$store.dispatch("doCreateCart");
                  } else {
                    console.log("Reading the cart");
                    const response = await this.$store.state.client.cart.show(
                      {orderToken: this.$store.state.accessToken.orderToken},
                      { include: "line_items"}
                      );

                    if (response.isSuccess()) {
                      await this.$store.dispatch("doUpdateCart", response.success());
                    } else {
                      await this.$store.dispatch("doCreateCart");
                    }
                  }
                }
        },
        async onSubmit() {
            const response = await ProductAPI.setSalesPrice(this, this.form.product_id, this.form.sale_price);
            if (response) {
              this.$bvModal.hide('product-sale-price-edit-modal');
            }
        }
    },
})
</script>
<template>
  <div id="ProductSalePriceEdit">
    <h3> Product SKU {{ form.sku }}</h3>
    <p><b>Name: </b> {{ form.name }} </p>
    <p><b>Price: </b> {{ form.price }} </p>

    <b-form @submit="onSubmit">
      <b-form-group
        id="set-sale-price-group"
        label="Product Sale Price"
        label-for="set-sale-price-input"
        description="Set Sale Price"
      >
        <b-form-input
          id="set-sale-price-input"
          v-model="form.sale_price"
          type="number"
          step="0.01"
          min="0"
          placeholder="Enter sale price"
        />
        <b-button
          type="submit"
          variant="primary"
        >
          Set sale price
        </b-button>
      </b-form-group>
    </b-form>
  </div>
</template>
