<script lang="ts">
import {defineComponent} from 'vue'
import {isArray, isEmpty} from "lodash";
import { ProductAPI } from '@/api/ProductAPI';
import {AddItem} from "@spree/storefront-api-v2-sdk/types/interfaces/endpoints/CartClass";
import axios from "axios";
import {API} from "@/api/API";
import '../assets/vue.scss'
import '../assets/intiferreira.scss'
import '../assets/brick.scss'

export default defineComponent({
  name: "ArticleAddForm",
  data() {
    const product = {};
    const variantSKU: string = "";

    return {
      variantSKU,
      product
    };
  },
  computed: {
    completed() {
      if (this.$store.state.cart && this.$store.state.cart.data) {
        console.log("completed:", this.$store.state.cart.data.attributes.completed_at);
        return !isEmpty(this.$store.state.cart.data.attributes.completed_at);
      } else {
        return false;
      }
    }
  },
  methods: {
    onDecode(evnt: any) {
      console.log("onDecode start", evnt);
      this.variantSKU = this.getProductSKU(evnt);
      this.$bvModal.hide("modal-qr-scan");
    },
    async setSalePrice() {
      console.log("setSalePrice - start");

      try {
        const productResult = (await ProductAPI.getProductByVariantSKU(this, this.variantSKU));

        this.product = {
          product_id: productResult.id,
          sku: this.variantSKU,
          name: productResult.name,
          description: productResult.description,
          price: productResult.price,
          sale_price: productResult.sale_price,
        };

        console.log("Product", this.product);

        this.$bvModal.show("product-sale-price-edit-modal");


      } catch (e) {
        this.$bvToast.toast(`The following exception has occurred ${e}`);
        return 0;
      }
    },
    async addToCart() {
      console.log("addToCart - start", JSON.stringify(this.$store.state.accessToken.orderToken));
      const client = this.$store.state.client;
      const variantId = await this.getVariantId(this.variantSKU);

      if (variantId === 0) {
        console.log("VARIANT NOT FOUND");
        return;
      }

      const params: AddItem = {
        variant_id: variantId,
        quantity: 1,
      };

      try {
        if (isEmpty(this.$store.state.cart)) {
          console.log("NO CART");
          return;
        }

        console.log("request to add article variant:", this.variantSKU, ", id: ", variantId, { t: this.$store.state.accessToken.orderToken, params});

        const response = await client.cart.addItem({orderToken: this.$store.state.accessToken.orderToken}, params);

        if (response.isSuccess()) {
          console.log("Added article", JSON.stringify(response.success()));
          const cartResponse = await this.$store.state.client.cart.show(
            {orderToken: this.$store.state.accessToken.orderToken},
            {include: "line_items"}
          );
          if (cartResponse.isSuccess()) {
            const c = cartResponse.success();
            console.log("Cart", JSON.stringify(c));
            await this.$store.dispatch("doUpdateCart", c);
          }
        } else {
          this.$bvToast.toast(response.fail().message);
          console.log(response.fail().message);
        }
      } catch (e) {
        console.log("Cart update problem: ", e);
      }
    },
    captureCode() {
      return true;
    },
    getProductSKU(str: string) {
      // TODO remove const _x = "https://www.intiferreira.eu/sh-825?dd=eyAic2t1Ijoic2gtODI1LTEtMS00MCIgfQ==";
      console.log("getProductSKU", str);

      try {
        // const regexp = /\?dd=(.*)\n$/gm;
        // const str2 = str.slice(0,str.length-1);
        // const matched = Buffer.from(str2).toString().match(/\?dd=(.*)$/gm);

        const encoded = str.slice(str.indexOf("?dd=") + 4);

        // console.log("buffer", str3);
        // const matched2 = "https://www.intiferreira.eu/sh-825?dd=eyAic2t1Ijoic2gtODI1LTEtMS00MCIgfQ==".match(/\?dd=(.*)$/);
        //
        // console.log("bufferx", Buffer.from(x));
        //
        // console.log( typeof str) ;
        // const encoded = matched ? matched[1] : "";
        // const encoded2 = matched2 ? matched2[1] : "";
        //
        console.log("matched", encoded);
        // console.log("matched2", encoded2);

        return JSON.parse(atob(encoded)).sku
      } catch (e) {
        if (str.indexOf("?refid=") > 0) {
          const newStr = str.replace("http://www.intiferreira.eu/", "").replace("https://www.intiferreira.eu/", "");
          return newStr.substr(0, newStr.indexOf("?refid="));
        } else {
          console.log(e);
          this.$bvToast.toast(`The following exception has occurred '${e}', the scanned value was: '${str}'`);
          return 0;
        }
      }
    },
    async getVariantId(sku: string) {
      try {
        return (await this.getVariant(sku)).id;
      } catch (e) {
        this.$bvToast.toast(`The following exception has occurred ${e}`);
        return 0;
      }
    },
    async getVariant(sku: string) {
      const errMsg = () => {
        return `Variant ${sku} not found`
      };
      try {
        const response = await axios.get(
          `${this.$store.state.client.cart.host}/api/v1/variants?q[sku_cont]=${sku}`,
          {
            headers: {'X-Spree-Token': API.getXSpreeToken(this.$store)},
          });

        console.log("VARIANT RESPONSE", JSON.stringify(response));

        if (response.data.variants && isArray(response.data.variants)) {
          const v = response.data.variants[0];
          if (v && v.sku === sku) {
            return v;
          } else {
            this.$bvToast.toast(errMsg());
            throw new Error(errMsg());
          }
        } else {
          this.$bvToast.toast(errMsg());
          throw new Error(errMsg());
        }
      } catch (e) {
        this.$bvToast.toast(errMsg());
        console.log(errMsg());
        throw e;
      }
    }
  }
});
</script>

<template>
  <div
    id="add-article-form"
    class="w-100"
    align="center"
  >
    <b-nav
      class="w-100"
      align="center"
    >
      <b-nav-item
        class="w-100"
        align="center"
      >
        <div class="well">
          <b-form
            class="m-auto d-flex"
            inline
            align="center"
          >
            <label
              class="sr-only"
              for="inline-form-input-name"
            >Name</label>
            <b-input
              id="inline-form-input-name"
              v-model="variantSKU"
              class="w-100 mb-4 mr-sm-4 mb-sm-0 ml-auto mr-auto"
              placeholder="Variant SKU"
              align-h="end"
            />

            <div class="ml-auto mr-auto">
              <b-button
                v-b-modal.modal-qr-scan
                class="ml-auto"
                variant="primary"
              >
                SCAN
              </b-button>
              <!--                                           <b-button class=""  v-b-modal.modal-qr-scan-2 variant="primary">Scan 2</span></b-button>-->
              <b-button
                class="ml-1 mr-auto"
                variant="primary"
                @disabled="completed"
                @click="addToCart"
              >
                Add
              </b-button>
              <b-button
                class="ml-1 mr-auto"
                variant="primary"
                @click="setSalePrice"
              >
                Set Sale Price
              </b-button>
            </div>
          </b-form>
        </div>
      </b-nav-item>
    </b-nav>

    <b-modal
      id="modal-qr-scan"
      ref="qrScanModal"
      hide-footer
      title="Scan QR Code"
    >
      <div
        v-if="captureCode"
        id="camera-capture"
        class="d-block text-center"
      >
        <qrcode-stream @decode="onDecode" />
      </div>
    </b-modal>


    <b-modal
      id="product-sale-price-edit-modal"
      ref="product-sale-price-edit-modal"
      hide-footer
      title="Sale Price"
    >
      <product-sale-price-edit v-bind="product" />
    </b-modal>
  </div>
</template>

<style scoped>

</style>
