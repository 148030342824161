import Vue from 'vue';
import Vuex from 'vuex';
import { isArray, parseInt } from "lodash";
import { IToken } from "@spree/storefront-api-v2-sdk/types/interfaces/Token";
import { Config } from "../Config";
import {APIv2} from "../api/APIv2";
import {API} from "@/api/API";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    client: APIv2.makeUpdatedClient({
          host: Config.spreeHost,
        }),
    user: localStorage.getItem("email") ? { email: localStorage.getItem("email"), password: localStorage.getItem("password") } : null,
    user_v1_token: localStorage.getItem("user_v1_token") ? localStorage.getItem("v1_token") : null,
    cart: null,
    line_items: [],
    payment_method: 'cash',
    accessToken: {
        orderToken: localStorage.getItem("orderToken") ? localStorage.getItem("orderToken") : "",
        bearerToken: localStorage.getItem("bearerToken") ? localStorage.getItem("bearerToken") : "",
      } as IToken,
    loggingIn: false,
    loginError: null,
    errorMessage: null,
    updating: false,
  },
  mutations: {
      loginStart: state => state.loggingIn = true,
      loginStop: (state, errorMessage) => {
        state.loggingIn = false;
        state.loginError = errorMessage;
      },
      userUpdate: (state, user) => {
        state.user = user;
      },
      userV1TokenUpdate: (state, token) => {
        state.user_v1_token = token;
      },
      cartUpdate: (state, cart) => {
        state.cart = cart;
      },
      tokenUpdate: (state, token) => {
        state.accessToken = token;
      },
      errorMessageUpdate: (state, payload) => state.errorMessage = payload,
      statusUpdate: (state, payload) => state.updating = payload,
      paymentUpdate: (state, payload) => state.payment_method = payload,
      updateClient: (state, client) => {
        state.client = client;
      }

    },
    actions: {
      doLogin({commit}, loginData) {
        return new Promise((resolve: any, reject: any) => {
          commit('loginStart');
          console.log("loginStart", loginData);

          this.state.client.authentication.getToken({
            username: loginData.email,
            password: loginData.password,
          }).then((tokenResponse => {
            console.log("STORE Login succeeded");
            setTimeout(async () => {
              if (tokenResponse.isSuccess()) {
                console.log("isSuccess", true)
                console.log(JSON.stringify(tokenResponse));


                const token: IToken = {
                  orderToken: this.state.accessToken.orderToken || "",
                  bearerToken: tokenResponse.success().bearerToken
                };

                commit('loginStop', null);
                commit('userUpdate', loginData);
                console.log("got IToken:", token);
                localStorage.setItem("email", loginData.email);
                localStorage.setItem("password", loginData.password);
                localStorage.setItem("bearerToken", token.bearerToken || "");
                localStorage.setItem("orderToken", token.orderToken || "");
                commit('tokenUpdate', token);

                API.getV1Token(this, loginData.email, loginData.password).then(
                   v1Response => {
                     console.log("v1Response", v1Response, "token:", { token });
                     commit('userV1TokenUpdate', v1Response);
                   }
                );

                resolve();
              } else {
                console.log("isSuccess:", false);
                commit('loginStop', null);    // TODO message
                commit('tokenUpdate', null);
                commit('userUpdate', null);
                localStorage.removeItem("email");
                localStorage.removeItem("password");
                localStorage.removeItem("bearerToken");
                localStorage.removeItem("orderToken");
              }
            }, 2000);
          })).catch((error: any) => {
            commit('loginStop', error.response.data.error);
            commit('tokenUpdate', null);
            commit('userUpdate', null);
            localStorage.removeItem("email");
            localStorage.removeItem("password");
            localStorage.removeItem("bearerToken");
            localStorage.removeItem("orderToken");
            reject();
          });



          // const token = {
          //   orderToken: "oT",
          //   bearerToken: "bT",
          // };

        });
      },
      doLogout({commit}, _loginData) {
        return new Promise((resolve, _reject) => {
          commit('loginStart');
          console.log("loginStart");
          commit('userUpdate', null);
          commit('tokenUpdate', { orderToken: "", bearerToken: "" } as IToken);
          commit("cartUpdate", null);
          localStorage.removeItem("email");
          localStorage.removeItem("password");
          localStorage.removeItem("bearerToken");
          localStorage.removeItem("orderToken");
          commit('loginStop', null);

          console.log("STATE AFTER LOGOUT: ", JSON.stringify(this.state));

          resolve(true);

        });
      },

      async doCreateCart( {commit} ) {
        console.log("doCreateCart", JSON.stringify(this.state.accessToken));
        try {
          const cartCreateResponse = await this.state.client.cart.create(this.state.accessToken);
          const orderToken = cartCreateResponse.success().data.attributes.token;
          const token: IToken  = { bearerToken: this.state.accessToken.bearerToken, orderToken };
          localStorage.setItem("orderToken", orderToken || "");
          token.orderToken = orderToken;
          const cart = cartCreateResponse.success();
          // (cart as any).axios.defaults.headers  = {
          //   ...(cart as any).axios.defaults.headers,
          //   'Cache-Control': 'no-cache'
          // };
          commit('cartUpdate', cart);
          commit('tokenUpdate', token);
          this.state.client.cart.axios.defaults.headers
        } catch (e) {
          console.log("token update error");
        }
      },

      async doUpdateCart( {commit}, cart ) {
              console.log("doUpdateCart");
              try {
                commit('statusUpdate', true);
                commit('cartUpdate', cart);
                commit('statusUpdate', false);
              } catch (e) {
                console.log("token update error");
              }
            },


      async doResetCart({commit} ) {
        console.log("resetting cart");
          await this.state.client.cart.emptyCart( this.state.accessToken );

          const cartCreateResponse = await this.state.client.cart.create();
                    const orderToken = cartCreateResponse.success().data.attributes.token;
                    const token: IToken  = this.state.accessToken;
                    token.orderToken = orderToken;
                    commit('cartUpdate', cartCreateResponse.success());
                    commit('tokenUpdate', token);
      },

      async doCheckout( { commit} ) {
        commit('checkoutStart');
        try {
          const cartCreateResponse = await this.state.client.cart.show({ orderToken: this.state.accessToken.orderToken} );
          if (cartCreateResponse.isFail()) {
            return;
          }

          const orderToken = cartCreateResponse.success().data.attributes.token;
          const shipping = (await this.state.client.checkout.shippingMethods({orderToken})).success();
          const shipping_rate_id: number = isArray(shipping.data[0].relationships.shipping_rates.data) ?
                    parseInt(shipping.data[0].relationships.shipping_rates.data[0].id) : 0;

          const payment = (await this.state.client.checkout.paymentMethods({orderToken})).success();
          console.log("PAYMENT: ", payment);

          await this.state.client.checkout.orderUpdate({orderToken}, {
            order: {
              shipments_attributes: [{
                id: parseInt(shipping.data[0].id),
                selected_shipping_rate_id: shipping_rate_id,
              }],
              payments_attributes: [{
                payment_method_id: parseInt(payment.data[0].id),
              }],
            }
          });
          await this.state.client.checkout.complete({orderToken})
        } catch (e) {

            console.log("An error occured while checking out.", e);
            commit('errorMessageUpdate', "An error occured while checking out.")
        }
      }
    },
  getters: {
    getUser: state => state.user
  }
});
