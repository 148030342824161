import { isArray } from "lodash";
import {CreateComponentPublicInstance} from "vue";
import { API } from "./API"

export class ProductAPI {
  static async getProductByVariantSKU(vue: CreateComponentPublicInstance, sku: string) {
     try {
       console.log("getProductByVariantSKU:  ", API.getProductsByVariantSku(sku));
       const errMsg = (ext?: string) =>`${ext || ""}Not found product for SKU '${sku}'`;
       const response = await API.getRequest(vue, API.getProductsByVariantSku(sku), errMsg);

       console.log("FOUND: ", response);

       if (response && response.data && isArray(response.data)) {
                const v = response.data[0];
                console.log("v = ", v);
                if (v) {
                  return v ;
                } else {
                  throw new Error(errMsg('1: '));
                }
              } else {

                throw new Error(errMsg('2: '));
              }
     } catch (e: any) {
       vue.$bvToast.toast(e.message);
       throw e;
     }
  }

  static async addItemToControlInventory(
    vue: CreateComponentPublicInstance,
    variantSKU: string,
    inventory_control_id: number,
    number_of_items: number = 1
  ) {
    console.log("addItemToControlInventory: ", API.addItemToControlInventory(variantSKU, inventory_control_id, number_of_items));
    const errMsg = () =>`Could not add to control repository '${variantSKU}'`;
    const successMsg = () =>`The count at hand for variant'${variantSKU}' increased`;
    return await API.updateRequest(vue, API.addItemToControlInventory(variantSKU, inventory_control_id, number_of_items), errMsg, successMsg);
  }

  static async updateItemInControlInventory(
    vue: CreateComponentPublicInstance,
    variantSKU: string,
    inventory_control_id: number,
    number_of_items: number = 1
  ) {
    console.log("updateItemInControlInventory: ", API.updateVerifiedInventoryItem(variantSKU, inventory_control_id, number_of_items));
    const errMsg = () =>`Could not update to control repository '${variantSKU}'`;
    const successMsg = () =>`Updated!`;
    return await API.updateRequest(vue, API.updateVerifiedInventoryItem(variantSKU, inventory_control_id, number_of_items), errMsg, successMsg);
  }

  static async removeItemFromControlInventory(
    vue: CreateComponentPublicInstance,
    variantSKU: string,
    inventory_control_id: number
  ) {
    console.log("removeItemFromControlInventory: ", API.removeVerifiedInventoryItem(variantSKU, inventory_control_id));
    const errMsg = () =>`Could not remove from control repository '${variantSKU}'`;
    const successMsg = () =>`removed '${variantSKU}/${inventory_control_id}'`;
    return await API.updateRequest(vue, API.removeVerifiedInventoryItem(variantSKU, inventory_control_id), errMsg, successMsg);
  }

  static async getActiveControlInventoryList(vue: CreateComponentPublicInstance) {
    console.log("getActiveControlInventoryList: ", API.getActiveInventoryControlsList());
    const errMsg = () =>`Could get list'`;

    return await API.getRequest(vue, API.getActiveInventoryControlsList(), errMsg);
  }

  static async getLastVerifiedInventoryItems(
    vue: CreateComponentPublicInstance, inventory_control_id: number) {
      console.log("getLastVerifiedInventoryItems: ", API.getLastVerifiedInventoryItemsList(inventory_control_id));
      const errMsg = () =>`Could get list'`;

      return await API.getRequest(vue, API.getLastVerifiedInventoryItemsList(inventory_control_id), errMsg);
    }

  static async setSalesPrice(
    vue: CreateComponentPublicInstance,
    productId: number,
    salePrice: number | null
  ) {
    console.log("setSalesPrice: ", API.updateProductSalePrice(productId, salePrice));
    const errMsg = () =>`Could not update sale price for '${productId}'`;
    const successMsg = () =>`Sale price for '${productId}' set to ${salePrice}`;
    return await API.updateRequest(vue, API.updateProductSalePrice(productId, salePrice), errMsg, successMsg);
  }

}
