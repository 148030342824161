import {Config} from "../Config";
import {makeClient} from "@spree/storefront-api-v2-sdk";
import {IClientConfig} from "@spree/storefront-api-v2-sdk/types/Client";


export class APIv2 {

  public static makeUpdatedClient(config?: IClientConfig) {
    const client = makeClient(config);
    const updatedURL = Config.spreeHost + '/' + Config.locale;
    client.account.axios.defaults.baseURL = updatedURL;
    // client.authentication = new Authentication(client.host)
    client.cart.axios.defaults.baseURL = updatedURL;
    client.checkout.axios.defaults.baseURL = updatedURL;
    client.countries.axios.defaults.baseURL = updatedURL;
    client.order.axios.defaults.baseURL = updatedURL;
    client.products.axios.defaults.baseURL = updatedURL;
    client.taxons.axios.defaults.baseURL = updatedURL;
    return client;
  }

}
