<script lang="ts">
import { defineComponent } from "vue";
import { isEmpty } from "lodash";
import '../assets/vue.scss'
import '../assets/intiferreira.scss'
import '../assets/brick.scss'


export default defineComponent({
  name: "PromotionsElement",
    data() {
      const form: { promotionCode: string } = { promotionCode: ""};

        return {
            client: this.$store.state.client,
            form
        };
    },
    computed: {
        line_items() {
            if (this.$store.state.cart && this.$store.state.cart.included) {
              console.log("LI:", this.$store.state.cart.included);
              return this.$store.state.cart.included;
            } else {
              return [];
            }
        }
    },
    mounted() {
        console.log(" line item list mounted!", JSON.stringify(this.$store.state));
    },
    methods: {
        async init() {

        },
        async applyPromotionCodeToCart() {
            try {
            if (!isEmpty(this.form.promotionCode)) {
             if (this.$store.state.accessToken.orderToken) {
               const response = await this.client.cart.applyCouponCode({ orderToken: this.$store.state.accessToken.orderToken}, { coupon_code: this.form.promotionCode } );
               if (response.isSuccess()) {
                 const cartResponse = await this.client.cart.show({ orderToken: this.$store.state.accessToken.orderToken } );
                 if (cartResponse.isSuccess()) {
                   await this.$store.dispatch("doUpdateCart", cartResponse.success())
                   this.$bvToast.toast("The promotion code has been applied");
                 }
               } else {
                 const responseCode = response.fail().serverResponse.status;
                 if (responseCode === 404) {
                   this.$bvToast.toast("Such code does not exist");
                 } else {
                   this.$bvToast.toast("The code could not be applied");
                 }
                 console.log(JSON.stringify(response.fail()));
               }
             }
            }
            } catch (e) {
            console.log("Apply promotion failed", e);
            }
        }
    },
})

</script>
<template>
  <div
    id="PromotionsElement"
    class="d-flex w-100 ml-auto"
    align="center"
  >
    <hr class="border-top my-3">
    <b-form
      class="w-50"
      inline
      @submit="applyPromotionCodeToCart"
    >
      <label
        class="sr-only"
        for="inline-form-input-name"
      >Name</label>
      <b-input
        id="inline-form-promotion-code"
        v-model="form.promotionCode"
        class="w-75"
        placeholder="Enter Code"
      />

      <b-button
        class="w-25"
        type="submit"
        variant="primary"
      >
        Apply
      </b-button>
    </b-form>
    <hr class="border-top my-3">
  </div>
</template>

