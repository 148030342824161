import axios, {AxiosRequestConfig, ResponseType} from "axios";
import {CreateComponentPublicInstance} from "vue";
import {Store} from 'vuex';
import {Config} from "../Config";

interface LoginResponse {
  token: string;
}

export class API {
  static getXSpreeToken(store: Store<any>)  {
    return store.state.user_v1_token;
  }

  static async getRequest(vue: CreateComponentPublicInstance, query: string, errorMessage: () => string, responseType?: ResponseType) {
    console.log('getRequest', query);

    const config: AxiosRequestConfig = {
                    headers: {
                      'X-Spree-Token' : API.getXSpreeToken(vue.$store),
                      // 'X-Spree-Token' : API.getXSpreeToken(),
                      'Cache-Control' : 'no-cache',
                    },
                  };

    if (responseType) {
      config.responseType = responseType;
    }

    const response = await axios.get(
              `${vue.$store.state.client.cart.host}${query}`,
               config
    );

    return response;
  }

  static async updateRequest(
    vue: CreateComponentPublicInstance,
    query: string, errorMessage: () => string,
    successMessage: () => string,
    params: any = { x: "x"}
  ) {
    try {
      const response = await axios.put(
        `${vue.$store.state.client.cart.host}${query}`, params,
        {
          headers: {'X-Spree-Token': API.getXSpreeToken(vue.$store)},
        });
      //vue.$bvModal.hide("product-sale-price-edit-modal");
      vue.$bvToast.toast(`Updated: ${successMessage()}`);
      return response;
    } catch (e: any) {
      vue.$bvToast.toast(e.message);
      return null;
    }
  }

  static async getV1Token(store: Store<any>, user: string, password: string): Promise<string> {
    const host = store.state.client.cart.host;
    try {
      const response = await axios.post<LoginResponse>(`${host}/api/v1/login`, {
        spree_user: {
          email: user,
          password: password
        }
      });

      // Handle the response as you see fit. Below, we return the token.
      return response.data.token;
    } catch (error: any) {
      if (error.response) {
        console.error('Axios error:', error.message);
        throw new Error(`Login failed: ${error.message}`);
      } else {
        console.error('Unexpected error:', error);
        throw new Error(`An unexpected error occurred: ${error}`);
      }
    }
  }

  static getVariantsBySku(sku: string) {
    return `/${this.getLocale()}/api/v1/variants?q[sku_cont]=${sku}`;
  }

  static getProductsByVariantSku(sku: string) {
      return `/${this.getLocale()}/api/v1/products/find_product_by_variant_sku/${sku}`;
  }

  static updateProductSalePrice(productId: number, salePrice: number | null ) {
      return `/${this.getLocale()}/api/v1/products/${productId}?product[sale_price]=${salePrice || ''}`;
  }

  static updateLineItemSalePrice(orderId: string, line_item_id: number, salePrice: number | null) {
    let sp  = null;
    if (salePrice) {
       sp = salePrice;
    }
    return `/${this.getLocale()}/api/v1/orders/${orderId}/line_items/${line_item_id}/set_sale_price?line_item[sale_price]=${ sp || ''}`;
  }

  static getInvoicePDF(orderNumber: string) {
    return `/${this.getLocale()}/api/v1/orders/get_invoice_for_order/${orderNumber}.pdf`
  }

  static getInvoiceHTML(orderNumber: string) {
    return `/${this.getLocale()}/api/v1/orders/get_invoice_for_order/${orderNumber}.html`
  }

  static sendInvoicePDF(orderNumber: string, email: string) {
    return `/${this.getLocale()}/api/v1/orders/send_invoice_for_order/${orderNumber}.pdf?email=${email}`
  }

  static addItemToControlInventory(sku: string, inventory_control_id: number, _number_of_items: number = 1) {
    return `/${this.getLocale()}/api/v1/inventory_controls/${inventory_control_id}/add_item/${sku}`
  }

  static updateVerifiedInventoryItem(sku: string, inventory_control_id: number, _number_of_items: number = 1) {
    return `/${this.getLocale()}/api/v1/inventory_controls/${inventory_control_id}/update_item/${sku}`
  }

  static removeVerifiedInventoryItem(sku: string, inventory_control_id: number) {
    return `/${this.getLocale()}/api/v1/inventory_controls/${inventory_control_id}/remove_item/${sku}`
  }

  static getActiveInventoryControlsList() {
    return `/${this.getLocale()}/api/v1/inventory_controls`
  }

  static getLastVerifiedInventoryItemsList(inventory_control_id: number) {
    return `/${this.getLocale()}/api/v1/inventory_controls/${inventory_control_id}/verified_inventory_items`
  }

  static getLocale() {
    return Config.locale;
  }
}
