import axios from "axios";
import { isEmpty, isArray,  } from "lodash";
import Vue from "vue";
import '../assets/vue.scss'
import '../assets/intiferreira.scss'
import '../assets/brick.scss'
import {API} from "../api/API";


export class VariantTool {

  async getVariant(vue: Vue, sku: string) {
      const errMsg = () => { return `Variant ${sku} not found`};
      try {
        const response = await axios.get(
          `${vue.$store.state.client.cart.host}${API.getVariantsBySku(sku)}}`,
          {
            headers: { 'X-Spree-Token' : API.getXSpreeToken(vue.$store)},
          });

        console.log("VARIANT RESPONSE", JSON.stringify(response));

        if (response.data.variants && isArray(response.data.variants)) {
          const v = response.data.variants[0];
          if (v && v.sku === sku) {
            return v ;
          } else {
            vue.$bvToast.toast(errMsg());
            throw new Error(errMsg());
          }
        } else {
          vue.$bvToast.toast(errMsg());
          throw new Error(errMsg());
        }
      } catch (e) {
        vue.$bvToast.toast(errMsg());
        console.log(errMsg());
        throw e;
      }
    }

    async getParentProduct() {

    }

    async setProductSalePrice() {

    }

    public static IdZero(id: any) {
      switch (typeof id) {
        case "number":
          return id != 0 ? id : 0;
        case "string":
          return isEmpty(id) ? "" : id;
        case "undefined":
        case "object":
          return id || "";
        case "boolean":
        case "function":
        case "symbol":
        case "bigint":
          return id || null;
      }
    }

}


