<script lang="ts">
import { defineComponent } from "vue";
import '../assets/vue.scss'
import '../assets/intiferreira.scss'
import '../assets/brick.scss'
import {cloneDeep, isEmpty} from "lodash";

import {API} from "../api/API";
import {Config} from "../Config";

export default defineComponent({
  name: "LineItemList",
    data() {
      const cart_info: any = {};
      const current_line_item: any = {};

        return {
            client: this.$store.state.client,
            current_line_item,
            cart_info
        };
    },
    computed: {
        cart() {
            if (this.$store.state.cart && this.$store.state.cart.data) {
              return this.$store.state.cart.data;
            } else {
              return {};
            }
        },
        isCart() {
            if (this.$store.state.cart) {
               return true;
            } else {
              return false;
            }
        },
        cartInfo() {
            if (this.$store.state.cart && this.$store.state.cart.data) {
              console.log("cartInfo: ", JSON.stringify(this.$store.state.cart.data), "\n ---- cartInfo ----");
              return this.$store.state.cart.data.attributes;
            } else {
              return {};
            }
        },
        line_items() {
            if (this.$store.state.cart && this.$store.state.cart.included) {
              const includedCopy = cloneDeep(this.$store.state.cart.included);
              return includedCopy.reverse();
            } else {
              return [];
            }
        },
        completed() {
            if (this.$store.state.cart && this.$store.state.cart.data) {
            console.log("completed:", this.$store.state.cart.data.attributes.completed_at);
            return !isEmpty(this.$store.state.cart.data.attributes.completed_at);
            } else {
            return false;
            }
        }
    },
    mounted() {
        console.log(" line item list mounted!", JSON.stringify(this.$store.state));

            this.init();
    },
    methods: {
        async init() {

        },
        shouldURLEncode() {
            const userAgent = navigator.userAgent || navigator.vendor;

                    // // Windows Phone must come first because its UA also contains "Android"
                    // if (/windows phone/i.test(userAgent)) {
                    //     return "Windows Phone";
                    // }

                    if (/android/i.test(userAgent)) {
                        return false;
                    }

                    // iOS detection from: http://stackoverflow.com/a/9039885/177710
                    if (/iPad|iPhone|iPod|Safari|Chrome/.test(userAgent)) {
                        return true;
                    }
        },
        b64DecodeUnicode(str: string) {
            return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            }).join(''))
        },
        async blobToBase64(blob: Blob): Promise<string> {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise(resolve => {
                reader.onloadend = () => {
                  const r = reader.result || '';
                  console.log("Read data: ", r);
                  let rs = null;
                  if (r instanceof String) {
                    console.log("Read data: -1", r);
                    rs = r
                      .replace('data:*/*;base64,','')
                      .replace('data:application/html;base64,','');
                  } else {
                    console.log("Read data -2: ", r.toString());
                    rs = r.toString()
                      .replace('data:*/*;base64,','')
                      .replace('data:application/html;base64,','')
                    ;
                  }
                  console.log("Read data -3",rs);
                  console.log(
                    this.b64DecodeUnicode(rs).toString().replace('data:*/*;base64,',''));
                  resolve(
                        this.b64DecodeUnicode(rs).toString().replace('data:*/*;base64,',''))
                  ;
                };
            });
        },
        async iOSPrintPDFBlob(blob: Blob) {
            // data:*/*;base64,
            const blob_base64 = await this.blobToBase64(blob);
            const callback_url = encodeURI(`${Config.spreeHost}`);
            const prt_url = encodeURI(`starpassprnt://v1/print/nopreview?pdf=${blob_base64}&size=3&drawer=off&back=${callback_url}`);
const link = document.createElement('a');
link.href = prt_url;
link.click();
},
        async iOSPrintHTMLBlob(blob: Blob) {
            // data:*/*;base64,
            console.log("Printing blob");
            const blob_base64 = await this.blobToBase64(blob);
            const html_text = this.shouldURLEncode()
            ? encodeURIComponent(this.b64DecodeUnicode(blob_base64).toString().replace('€', '&euro;'))
            : blob_base64;
            console.log("HTML: ", html_text);
            const callback_url = encodeURI(`${Config.spreeHost}`);
            const prt_url = encodeURI(`starpassprnt://v1/print/nopreview?html=${html_text}&size=3&drawer=off&back=${callback_url}`);
const link = document.createElement('a');
link.href = prt_url;
link.click();
},
        async downloadPDFBlob(blob: Blob, invoiceNumber: string) {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download=`invoice_${invoiceNumber}.pdf`;
            link.click();
        },
        downloadInvoice(number: string) {
            try {
              console.log("Downloading invoice number: ", number)
                API.getRequest(this, API.getInvoicePDF(number), () => 'Found', 'blob').then(
                  async response => {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    await this.downloadPDFBlob(blob, number);
                  }
                );
            } catch (e) {
              this.$bvToast.toast(`Could not download the invoice for the order ${number}`);
            }
        },
        async printInvoice(number: string) {
            try {
              console.log("Printing invoice number: ", number)
              const response = await API.getRequest(this, API.getInvoiceHTML(number), () => 'Found', 'blob');
              console.log("1- Response data: ", response.data);
              const blob = new Blob([response.data], { type: 'application/html' });
                  console.log(blob);
                  await this.iOSPrintHTMLBlob(blob);
            } catch (e) {
              this.$bvToast.toast(`Could not print the invoice for the order ${number}`);
            }
        },
        async setPriceItem(item: any) {
            console.log("setPriceItem - start", JSON.stringify(this.$store.state.accessToken.orderToken));
                try {
                   this.current_line_item = {
                     line_item_id: item.id,
                     sku: item.attributes.slug,
                     name: item.attributes.name,
                     description: item.attributes.description,
                     order_number: this.cartInfo.number,
                     sale_price: item.attributes.price,
                   };
                   console.log("Curernt line item", this.current_line_item);
                   await this.reloadCart();
                   this.cart_info = { info: this.cartInfo };
                   this.$bvModal.show("line-item-sale-price-edit-modal");

                } catch (e: any) {
                  console.log("This is error afterrefreshing the cart", e);
                  this.$bvToast
                    .toast(`Could not set price for the item id: ${item}, slug: ${item.slug}, ${ e.inspect }`);
                }
        },
        async removeItem(item: any) {
            console.log("removeItem - start", JSON.stringify(this.$store.state.accessToken.orderToken));

                try {
                  // TODO const client = this.$store.state.client;

                  const cartResponse = await this.$store.state.client.cart.removeItem(
                    {orderToken: this.$store.state.accessToken.orderToken},
                    item.id,
                  );

                  if (cartResponse.isSuccess()) {
                    this.$bvToast.toast(`Removed item id: ${item}, slug: ${item.slug}`)
                  } else {
                    this.$bvToast.toast(`Could not remove item id: ${item}, slug: ${item.slug}`)
                  }

                  await this.reloadCart()
                } catch (e: any) {
                  this.$bvToast.toast(`Could not remove item id: ${item}, slug: ${item.slug}, ${e.inspect}`);
                }
        },
        async rereadCart() {
            const response = await this.$store.state.client.cart.show(
                  {orderToken: this.$store.state.accessToken.orderToken},
                  {include: "line_items"}
                );

                if (response.isSuccess()) {
                  await this.$store.dispatch("doUpdateCart", response.success());
                } else {
                  await this.$store.dispatch("doCreateCart");
                }
        },
        async reloadCart() {
            console.log("Reading the cart");
                const response = await this.$store.state.client.cart.show(
                  {orderToken: this.$store.state.accessToken.orderToken},
                  {include: "line_items"}
                );

                if (response.isSuccess()) {
                  await this.$store.dispatch("doUpdateCart", response.success());
                }
        }
    },
})

</script>
<template>
  <div
    id="LineItemList"
    class="w-100"
  >
    <div
      v-if="isCart"
      id="CartSummary"
      class="w-100"
    >
      <b-list-group>
        <b-list-group-item>
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">
              <div v-if="completed">
                [COMPLETED]
              </div>Order Number
            </h5>
            <h5 class="text-muted">
              {{ cartInfo.number }}
            </h5>
          </div>
        </b-list-group-item>
        <b-list-group-item :key="cart_info.info">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">
              Total
            </h5>
            <h5 class="text-muted">
              {{ cartInfo.total }} EUR
            </h5>
          </div>
        </b-list-group-item>
        <b-list-group-item v-if="completed">
          <b-button
            class="w-100"
            right
            @click="printInvoice(cartInfo.number)"
          >
            Print Invoice
          </b-button>
          <b-button
            class="w-100"
            right
            @click="downloadInvoice(cartInfo.number)"
          >
            Download Invoice
          </b-button>
          <send-invoice-mail />
        </b-list-group-item>
      </b-list-group>
    </div>
    <h3 v-if="!completed">
      Line Items
    </h3> <br>
    <b-list-group>
      <b-list-group-item
        v-for="line_item in line_items"
        :key="line_item.attributes.display_total"
      >
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">
            {{ line_item.attributes.slug }}
          </h5>
        </div>
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">
            {{ line_item.attributes.name }}
          </h5>
        </div>
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">
            Options: {{ line_item.attributes.options_text }}
          </h5>
        </div>
        <h5 class="text-muted">
          Quantity: {{ line_item.attributes.quantity }}
        </h5>
        <div class="d-flex w-100 justify-content-between">
          <p v-if="line_item.attributes.original_price">
            <b>Original price:</b>&euro; {{ line_item.attributes.original_price }}
          </p>
          <p><b>Price: </b>&euro; {{ line_item.attributes.display_total }}</p>
        </div>
        <div class="d-flex w-100 right">
          <b-button
            right
            @click="removeItem(line_item)"
          >
            Remove
          </b-button>
          <b-button
            right
            @click="setPriceItem(line_item)"
          >
            Set Special Price
          </b-button>
        </div>
      </b-list-group-item>
    </b-list-group>
    <b-modal
      id="line-item-sale-price-edit-modal"
      ref="line-item-sale-price-edit-modal"
      hide-footer
      title="Sale Price"
    >
      <line-item-sale-price-edit v-bind="current_line_item" />
    </b-modal>
  </div>
</template>


