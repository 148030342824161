<script lang="ts">
import { defineComponent } from "vue";
import '../assets/vue.scss'
import '../assets/intiferreira.scss'
import '../assets/brick.scss';
import { isArray, } from "lodash";
import axios from 'axios';
import {ProductAPI} from "../api/ProductAPI";
import {API} from "../api/API";

export default defineComponent({
    data() {
      const lastItems: any = [];
      const optionsForInventoryControl: any = {};
      const controlInventory: any = {};
      const variant: any = {};
      const variantSKU: string = "";

        return {
            variantSKU,
            variant,
            controlInventory,
            number_of_items: 1,
            optionsForInventoryControl,
            lastItems
        };
    },
    mounted() {
        this.getOptionsForInventoryControls().then(
          () => this.reload_items()
        );
    },
    methods: {
        onDecode(evnt: any) {
            console.log("onDecode start", evnt);
            this.variantSKU = this.getProductSKU(evnt);
            this.$bvModal.hide("modal-qr-scan");
        },
        reload_items() {
            this.assignLastVerifiedItems();
        },
        async addToControlInventory() {
          console.log("addToControlInventory - start", JSON.stringify(this.$store.state.accessToken.orderToken));

                try {
                  console.log("add to control,  variant SKU:", this.variantSKU);
                  const controlVariant = await ProductAPI.addItemToControlInventory(this, this.variantSKU, this.controlInventory.id, this.number_of_items);

                  if (controlVariant != null) {
                    this.assignLastVerifiedItems();

                    this.$bvToast.toast("Success");
                    console.log("Added Item Data", controlVariant.data);
                  }
                } catch (e) {
                  console.log("Problem: ", e);
                }
        },
        captureCode() {
            return true;
        },
        getProductSKU(str: string) {
            // TODO REMOVE const _x = "https://www.intiferreira.eu/sh-825?dd=eyAic2t1Ijoic2gtODI1LTEtMS00MCIgfQ==";
                console.log("getProductSKU", str);

                try {
                  // const regexp = /\?dd=(.*)\n$/gm;
                  // const str2 = str.slice(0,str.length-1);
                  // const matched = Buffer.from(str2).toString().match(/\?dd=(.*)$/gm);

                  const encoded = str.slice(str.indexOf("?dd=")+4);

                  // console.log("buffer", str3);
                  // const matched2 = "https://www.intiferreira.eu/sh-825?dd=eyAic2t1Ijoic2gtODI1LTEtMS00MCIgfQ==".match(/\?dd=(.*)$/);
                  //
                  // console.log("bufferx", Buffer.from(x));
                  //
                  // console.log( typeof str) ;
                  // const encoded = matched ? matched[1] : "";
                  // const encoded2 = matched2 ? matched2[1] : "";
                  //
                  console.log("matched", encoded);
                  // console.log("matched2", encoded2);

                  return JSON.parse(atob(encoded)).sku
                } catch (e) {
                  if (str.indexOf("?refid=") > 0) {
                    const newStr = str.replace("http://www.intiferreira.eu/", "").replace("https://www.intiferreira.eu/", "");
                    return newStr.substr(0, newStr.indexOf("?refid="));
                  } else {
                    console.log(e);
                    this.$bvToast.toast(`The following exception has occurred '${e}', the scanned value was: '${str}'`);
                    return 0;
                  }
                }
        },
        async getVariantId(sku: string) {
            try {
              return (await this.getVariant(sku)).id;
            } catch (e) {
              this.$bvToast.toast(`The following exception has occurred ${e}`);
              return 0;
            }
        },
        async getOptionsForInventoryControls() {
            const controls = await ProductAPI.getActiveControlInventoryList(this);
            console.log("Controls List", controls);
            if (controls != null) {
              console.log("Controls List Data", controls.data);
              this.optionsForInventoryControl = controls.data.map( (control: any)  => {
                return { text: control.name, value: control }
              });
              this.controlInventory = this.optionsForInventoryControl[0].value;
            }
        },
        async assignLastVerifiedItems() {
            if (this.controlInventory != null) {
              const lasts = await ProductAPI.getLastVerifiedInventoryItems(this, this.controlInventory.id);
              if (lasts !== null) {
                this.lastItems = lasts.data.map( (d: any) => {
                  // d = JSON.parse(d); // TODO
                  console.log("VI: ", d);
                  return {
                    variant_sku: d.variant_sku,
                    count_on_hand: d.count_on_hand,
                    variant_id: d.variant_id,
                    color: d.color
                  }
                });
              } else {
                this.lastItems = [];
              }
            }
            else {
              this.lastItems = [];
            }
        },
        async getVariant(sku: string) {
            const errMsg = () => { return `Variant ${sku} not found`};
                try {
                  const response = await axios.get(
                    `${this.$store.state.client.cart.host}/api/v1/variants?q[sku_cont]=${sku}`,
                    {
                      headers: { 'X-Spree-Token' : API.getXSpreeToken(this.$store)},
                    });

                  console.log("VARIANT RESPONSE", JSON.stringify(response));

                  if (response.data.variants && isArray(response.data.variants)) {
                    const v = response.data.variants[0];
                    if (v && v.sku === sku) {
                      return v ;
                    } else {
                      this.$bvToast.toast(errMsg());
                      throw new Error(errMsg());
                    }
                  } else {
                    this.$bvToast.toast(errMsg());
                    throw new Error(errMsg());
                  }
                } catch (e) {
                  this.$bvToast.toast(errMsg());
                  console.log(errMsg());
                  throw e;
                }
        }
    },
})

</script>
<template>
  <div
    id="control-inventory-add-form"
    class="w-100"
    align="center"
  >
    <b-nav
      class="w-100"
      align="center"
    >
      <b-nav-item
        class="w-100"
        align="center"
      >
        <div class="well">
          <b-form
            class="m-auto"
            inline
            align="center"
          >
            <b-form-select
              v-model="controlInventory"
              :options="optionsForInventoryControl"
              class="form-control mr-sm-4 w-100"
              @change="reload_items"
            />
            <b-input
              id="inline-form-input-name"
              v-model="variantSKU"
              class="w-100 mb-4 mr-sm-4 mb-sm-0 ml-auto mr-auto"
              placeholder="Variant SKU"
              align-h="end"
            />

            <div class="ml-auto mr-auto">
              <b-button
                v-b-modal.modal-qr-scan
                class="ml-auto"
                variant="primary"
              >
                Scan
              </b-button>
              <b-button
                class="ml-1 mr-auto"
                variant="primary"
                @click="addToControlInventory"
              >
                Add to Control
              </b-button>
            </div>
          </b-form>
        </div>
      </b-nav-item>
    </b-nav>

    <b-dd-divider />

    <b-card v-if="lastItems">
      <b-card-header>Last Items</b-card-header>
      <b-card-body>
        <b-table :items="lastItems" />
      </b-card-body>
    </b-card>

    <b-modal
      id="modal-qr-scan"
      ref="qrScanModal"
      hide-footer
      title="Scan QR Code"
    >
      <div
        v-if="captureCode"
        id="camera-capture"
        class="d-block text-center"
      >
        <qrcode-stream @decode="onDecode" />
      </div>
    </b-modal>
  </div>
</template>
