<template>
  <div id="SalespointLogin">
    <salespoint-header />
    <!-- <img :src="require('../assets/logo.png')" alt="LOGO"> -->
    <h1>{{ msg }}</h1>
    <b-form
      v-if="show"
      @submit="onSubmit"
      @reset="onReset"
    >
      <b-form-group
        id="login-group"
        label="Login form"
        label-for="login-input"
        description="Email address of the shop operator"
      >
        <b-form-input
          id="login-input"
          v-model="form.email"
          type="email"
          required
          placeholder="Enter email"
        />
        <b-form-input
          id="password-input"
          v-model="form.password"
          type="password"
          placeholder="Enter password"
        />
        <b-button
          type="submit"
          variant="primary"
        >
          Login
        </b-button>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import '../assets/vue.scss';
import '../assets/intiferreira.scss';
import '../assets/brick.scss';

export default {
  name: "SalespointLogin",
  data() {
    return {
      msg: "Welcome to Salespoint",
      form: { email: "", password: "" },
      show: true,
    };
  },
  methods: {
    mounted() {
      console.log("mounted!", JSON.stringify(this.$store.state));
      this.init();
    },
    init() {
      console.log("!!!");
    },
    async login(evt) {
      console.log('login', evt)
      try {
        await this.$store.dispatch("doLogin", this.form);
        console.log("logged in completed");
        this.$router.push("/");
      } catch (err) {
        console.log("Login failed", err);
      }
    },
    async onSubmit(evt) {
      evt.preventDefault();
      await this.login(evt);
    },
    onReset(evt) {
      console.log("onReset", evt);
    },
  },
};
</script>

<style scoped>
</style>
