<script lang="ts">
import { defineComponent } from "vue";
import '../assets/vue.scss'
import '../assets/intiferreira.scss'
import '../assets/brick.scss'

// import '../assets/logo.png';

export default defineComponent({
  name: "SalespointHeader",
    data() {
      const show: boolean = true;
      const form: { email: string, password: string } = {email: "", password: ""};
      const msg: string = "IF Salespoint";

        return {
            msg,
            form,
            show
        };
    },
    mounted() {
        console.log("mounted!");
        this.init();
    },
  methods: {
    init() {
      console.log("STATUS1: ", JSON.stringify(this.$store.state));
      console.log("!!!")
    },
    logout(evt: any) {
      console.log("logout", {evt})
      this.$store.dispatch("doLogout").then(
        () => {
          console.log("logged out");
          this.$router.push("/login")
        }
      ).catch((err) => console.log("Logout failed", {err}));
    },
    control(evt: any) {
      console.log("going to control", {evt});
      this.$router.push("/control");
    },
    checkout(evt: any) {
      console.log("going to checkout", {evt});
      this.$router.push("/");
    },
    user() {
      console.log("STATUS2: ", JSON.stringify(this.$store.state));
      return this.$store.state.user;
    }
  },
})
</script>
<template>
  <div>
    <b-navbar
      type="dark"
      variant="info"
    >
      <b-navbar-brand href="#">
        {{ msg }}
      </b-navbar-brand>
      <!--             <img src="@assets/logo.png"/>-->
      <b-navbar-nav class="ml-auto">
        <b-nav-text
          v-if="user"
          class="d-none d-sm-block"
          right
        >
          {{ user() ? user().email : "" }}
        </b-nav-text>
        <b-button
          v-if="user() !== null && $route.path !== '/control'"
          class="ml-2"
          @click="control"
        >
          Control
        </b-button>
        <b-button
          v-if="user() !== null && $route.path === '/control'"
          class="ml-2"
          @click="checkout"
        >
          Checkout
        </b-button>
        <b-button
          v-if="user() !== null"
          class="ml-2"
          @click="logout"
        >
          Logout
        </b-button>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>


