<script lang="ts">
import { defineComponent } from "vue";
import '../assets/vue.scss'
import '../assets/intiferreira.scss'
import '../assets/brick.scss'
import { isEmpty } from "lodash";

export default defineComponent({
    data() {
        const show: boolean = true;
        const form: { email: string, password: string } = { email: "", password: ""};
        const msg: string = "Cart";
        const selected_payment: string = "Cash";
        const user: any = {}

        return {
            selected_payment,
            msg,
            form,
            show,
          user
        };
    },
    computed: {
        cart() {
            if (this.$store.state.cart && this.$store.state.cart.data) {
            return this.$store.state.cart.data;
            } else {
            return {};
            }
        },
        isCart() {
            if (this.$store.state.cart && this.$store.state.cart.data) {
            console.log("completed:", this.$store.state.cart.data.attributes);
            return !isEmpty(this.$store.state.cart.data.relationships.line_items.data);
            } else {
            return false;
            }
        },
        completed() {
            if (this.$store.state.cart && this.$store.state.cart.data) {
            console.log("completed:", this.$store.state.cart.data.attributes.completed_at);
            return !isEmpty(this.$store.state.cart.data.attributes.completed_at);
            } else {
            return false;
            }
        }
    },
    async mounted() {
        if (!this.$store.state.user && localStorage.user) {
        this.user = localStorage.user;
        }
        console.log("mounted!")
        this.init();
    },
    methods: {
        async init() {
            if (!this.$store.state.cart) {
                    console.log("Cart was null");
                    console.log("local storage orderToken", localStorage.getItem("orderToken"));
                    console.log("", JSON.stringify(this.$store.state.accessToken));
                    if (isEmpty(this.$store.state.accessToken.orderToken)) {
                      await this.$store.dispatch("doCreateCart");
                    } else {
                      console.log("Reading the cart");
                      const response = await this.$store.state.client.cart.show(
                        {orderToken: this.$store.state.accessToken.orderToken},
                        { include: "line_items"}
                        );

                      if (response.isSuccess()) {
                        await this.$store.dispatch("doUpdateCart", response.success());
                      } else {
                        await this.$store.dispatch("doCreateCart");
                      }
                    }
                  }
        },
        async checkout() {
            console.log("start checkout");

                  const address = {
                                 firstname: "Semsa",
                                 lastname: "Ferreira",
                                 address1: "Spui 33H",
                                 city: "Den Haag",
                                 phone: "703359886",
                                 zipcode: "2511 BL",
                                 state_name: "Zuid-Holland",
                                 country_iso: "NL"
                               };

                  const client = this.$store.state.client;

                  try {

                    const responseAddress = await client.checkout.orderUpdate({orderToken: this.$store.state.accessToken.orderToken}, {
                      order: {
                        email: this.$store.state.user.email,
                        bill_address_attributes: address,
                        ship_address_attributes: address,
                      }
                    });

                    if (responseAddress.isFail()) {
                      console.log("Address fail");
                    }

                    const responseShipping = (await client.checkout.shippingMethods({orderToken: this.$store.state.accessToken.orderToken}));
                    if (responseShipping.isSuccess()) {
                      const shipping = responseShipping.success();
                      console.log("Shipping: ", shipping);

                      const responsePayment = await client.checkout.paymentMethods({orderToken: this.$store.state.accessToken.orderToken});
                      if (responsePayment.isFail()) {
                        return;
                      }

                      const payment = responsePayment.success();
                      console.log("Payment: ", payment);

                      let payId = 3;
                      for (const payMethod of payment.data) {
                        if (payMethod.attributes.name.toLowerCase() === this.selected_payment.toLowerCase() )  {
                            payId = payMethod.id;
                        }
                        console.log("payId:", payId); // prints values: 10, 20, 30, 40
                      }
                      // payment.data

                      const response = await client.checkout.orderUpdate({orderToken: this.$store.state.accessToken.orderToken}, {
                        order: {
                          email: this.$store.state.user.email,
                          bill_address_attributes: address,
                          ship_address_attributes: address,
                          payments_attributes: [
                            { payment_method_id: payId }
                          ]
                        }
                      });

                      if (response.isFail()) {
                        console.log("Update Order failed", JSON.stringify(response.fail()));
                        return;
                      }

                      const responseShippingRate = await client.checkout.orderUpdate({orderToken: this.$store.state.accessToken.orderToken}, {
                        order: {
                          shipments_attributes: [{
                            id: shipping.data[0].id,
                            selected_shipping_rate_id: shipping.data[0].relationships.shipping_rates.data[0].id
                          }]
                        }
                      });

                      if (responseShippingRate.isFail()) {
                        console.log("ShippingRate failed", JSON.stringify(responseShippingRate.fail()));
                        return;
                      }


                      console.log("Order Update: ", JSON.stringify(response.success()));

                        const responseComplete =  await client.checkout.complete({ orderToken: this.$store.state.accessToken.orderToken });
                        if (responseComplete.isSuccess()) {
                          this.$store.dispatch("doUpdateCart", responseComplete.success());
                          this.$bvToast.toast(`The order ${responseComplete.success().data.attributes.number} has been completed.`,
                            { title: "ORDER STATUS ", toaster: "b-toaster-top-center" });
                          console.log("COMPLETE", JSON.stringify(responseComplete.success()));
                        } else {
                          console.log("COMPLETE FAILURE", JSON.stringify(responseComplete.fail()));
                        }
                    }
                  } catch (e) {
                    console.log("Checkout failed", e);
                  }
        },
        async reset() {
            await this.$store.dispatch("doResetCart");
        }
    },
})
</script>
<template>
  <div id="SalespointCartPage">
    <salespoint-header />

    <b-container class="bv-example-row">
      <b-row v-if="isCart && !completed">
        <promotions-element />
      </b-row>
      <b-row>
        <b-col>
          <article-add-form v-if="!completed" />
        </b-col>
      </b-row>
      <b-row
        v-if="isCart"
        align="center"
      >
        <line-item-list align="center" />
      </b-row>
    </b-container>
    <b-form-group
      v-if="isCart && !completed"
      label="Payment method"
    >
      <b-form-radio
        v-model="selected_payment"
        name="some-radios"
        value="PIN"
      >
        PIN / CC
      </b-form-radio>
      <b-form-radio
        v-model="selected_payment"
        name="some-radios"
        value="Cash"
      >
        Cash
      </b-form-radio>
    </b-form-group>

    <b-nav
      v-if="isCart"
      class=""
      align="center"
    >
      <b-button
        v-if="isCart && !completed"
        @click="checkout"
      >
        Checkout
      </b-button>
      <b-button @click="reset">
        Reset cart
      </b-button>
    </b-nav>
  </div>
</template>




