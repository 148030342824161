import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from "vuex"
import BootstrapVue from 'bootstrap-vue'
import store from './store'
import App from './App.vue'

import './assets/vue.scss'
import './assets/intiferreira.scss'
import './assets/brick.scss'
import SalespointCart from './components/SalesPointCart.vue'
import SalespointHeaderComponent from "./components/SalespointHeader.vue";
import ArticleAddComponent from "./components/ArticleAddForm.vue";

import VueQrcodeReader from "vue-qrcode-reader";
import LineItemListComponent from "./components/LineItemList.vue";
import PromotionsElementComponent from "./components/PromotionsElement.vue";

// import VueQrReader from 'vue-qr-reader/dist/lib/vue-qr-reader.umd.js';
import ProductSalePriceEditComponent from "./components/ProductSalePriceEdit.vue";
import SendInvoiceMailComponent from "./components/SendInvoiceMail.vue";
import ControlInventory from "./components/ControlInventory.vue";
import ControlInventoryAddComponent from "./components/ControlInventoryAddForm.vue";
import LineItemSalePriceEditComponent from "./components/LineItemSalePriceEdit.vue";
import SalespointLogin from "@/components/SalespointLogin.vue";

Vue.use(VueQrcodeReader);
// Vue.use();


Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(Vuex);

Vue.component('SalespointHeader', SalespointHeaderComponent);
Vue.component('ArticleAddForm', ArticleAddComponent);
Vue.component('LineItemList', LineItemListComponent);
Vue.component('PromotionsElement', PromotionsElementComponent);
Vue.component('ProductSalePriceEdit', ProductSalePriceEditComponent);
Vue.component('LineItemSalePriceEdit', LineItemSalePriceEditComponent);
Vue.component('SendInvoiceMail', SendInvoiceMailComponent);
Vue.component('ControlInventory', ControlInventory);
Vue.component('ControlInventoryAddForm', ControlInventoryAddComponent);


const requireAuth = (to: any, from: any, next: any) => {
  console.log("STORE STATE: ", store.state);
  if( store.getters.getUser != null){
        next();
      }else{
        console.log("should login");
        next('/login');
      }
};

const routes = [
  { path: '/login', component: SalespointLogin },
  { path: '/', component: SalespointCart, beforeEnter: requireAuth },
  { path: '/control', component: ControlInventory }
];

const router = new VueRouter( { routes } );



new Vue({
  router: router,
  store,
  render:  h => h(App)
}).$mount('#app');
